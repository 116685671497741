'use client';

import { getPortfolioItems } from '@/resources/messages/portfolio/portfolio';
import { useLocale, useTranslations } from 'next-intl';
import { useRouter, useSearchParams } from 'next/navigation';
import {
	FC,
	useCallback,
	useEffect,
	useId,
	useMemo,
	useRef,
	useState,
} from 'react';
import { CheckboxGroup, Checkbox } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { AnimatePresence, motion, useReducedMotion } from 'motion/react';
import { Locale } from '@/i18n/routing';
import Link from 'next/link';
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiDotsHorizontal } from '@mdi/js';

const PortfolioTags: FC = () => {
	const locale = useLocale() as Locale;
	const translations = useTranslations('Portfolio.Tags');
	const searchParams = useSearchParams();
	const router = useRouter();
	const reduceMotion = useReducedMotion();

	const tagsListRef = useRef<HTMLDivElement>(null);
	const headingId = useId();

	const [isExpanded, setIsExpanded] = useState(false);

	const enabledTags = useMemo(() => {
		const tags = searchParams.get('portfolioTags');
		if (!tags) return [];
		return tags.split(',');
	}, [searchParams]);

	const allTags = useMemo(
		() => getPortfolioItems(locale as Locale).tags,
		[locale],
	);

	const orderedTags = useMemo(() => {
		// Put the enabled tags first in the list of tags
		const enabledTagsSet = new Set(enabledTags);
		const enabledTagsFirst = allTags.filter((tag) =>
			enabledTagsSet.has(tag),
		);

		// Put the rest of the tags after the enabled tags
		const restOfTags = allTags.filter((tag) => !enabledTagsSet.has(tag));

		const tagsCombined = [...enabledTagsFirst, ...restOfTags];

		// If not expanded, only show the first few tags
		return isExpanded
			? tagsCombined
			: tagsCombined.slice(
					0,
					typeof window !== 'undefined' && window.innerWidth < 640
						? 14
						: 20,
			  );
	}, [allTags, enabledTags, isExpanded]);

	// Scroll to the tags when the tags change or on mount if a tag is in the url
	useEffect(() => {
		if (enabledTags.length > 0) {
			setTimeout(
				() =>
					tagsListRef.current?.scrollIntoView({
						behavior: reduceMotion ? 'auto' : 'smooth',
					}),
				500,
			);
		}
	}, [enabledTags, reduceMotion]);

	const toggleTag = useCallback(
		(tag: string) => {
			const newSearchParams = new URLSearchParams(
				searchParams.toString(),
			);

			// Edit the new search params to add to or remove the tag from the portfolioTags query parameter
			newSearchParams.set(
				'portfolioTags',
				enabledTags.includes(tag)
					? enabledTags.filter((t) => t !== tag).join(',')
					: [...enabledTags, tag].join(','),
			);

			// If the portfolioTags query parameter is empty, remove it from the search params
			if (newSearchParams.get('portfolioTags') === '') {
				newSearchParams.delete('portfolioTags');
			}

			// If search params are empty, just navigate to the page without any search params
			if (newSearchParams.toString() === '') {
				router.push(location.href.split('?')[0], {
					scroll: false,
				});
				setIsExpanded(false);
				return;
			}

			// Navigate to the same page as we're on right now but with the new search params (url string)
			const pageBaseUrl = location.href.split('?')[0];
			router.push(`${pageBaseUrl}?${newSearchParams.toString()}`, {
				scroll: false,
			});
		},
		[enabledTags, router, searchParams],
	);

	return (
		<>
			<h3 id={headingId} className="sr-only">
				Tags
			</h3>
			<CheckboxGroup
				ref={tagsListRef}
				aria-labelledby={headingId}
				className={twMerge(
					'flex flex-wrap items-center justify-center gap-2 py-4',
				)}
			>
				<AnimatePresence>
					{orderedTags.map((tag) => (
						<motion.div
							key={tag}
							layoutId={reduceMotion ? undefined : `tag-${tag}`}
							layoutDependency={`${orderedTags}-${enabledTags}-${isExpanded}`}
							lang="en"
							className={twMerge(
								enabledTags.includes(tag) && 'z-10',
							)}
							tabIndex={-1}
							initial={{
								opacity: 0,
								scale: reduceMotion ? 1 : 0.5,
							}}
							animate={{
								opacity: 1,
								scale: 1,
							}}
							whileHover={{
								scale: reduceMotion ? 1 : 1.025,
							}}
							whileTap={{
								scale: reduceMotion ? 1 : 0.9,
							}}
							exit={{
								opacity: 0,
								scale: reduceMotion ? 1 : 0.5,
							}}
						>
							<Checkbox
								value={tag}
								isSelected={enabledTags.includes(tag)}
								onChange={() => toggleTag(tag)}
								className={twMerge(
									'relative block px-4 py-1',
									'rounded-full border-2 border-black/10 dark:border-white/10 text-lg',
									'cursor-pointer hover:shadow-lg',
									'transition-all',
									enabledTags.includes(tag)
										? 'bg-rose-600 text-white dark:bg-white/90 dark:text-rose-700 shadow-lg -rotate-2 scale-105'
										: 'bg-white/60 text-slate-900 dark:bg-slate-900/60 dark:text-white',
								)}
							>
								{tag.toLocaleLowerCase()}
							</Checkbox>
						</motion.div>
					))}
				</AnimatePresence>

				<motion.div
					key="tags-more"
					layoutId="tags-more"
					layoutDependency={`${orderedTags}-${enabledTags}-${isExpanded}`}
					className={twMerge(isExpanded && 'z-10')}
					tabIndex={-1}
					initial={{
						opacity: 0,
						scale: reduceMotion ? 1 : 0.5,
					}}
					animate={{
						opacity: 1,
						scale: 1,
					}}
					whileHover={{
						scale: reduceMotion ? 1 : 1.025,
					}}
					whileTap={{
						scale: reduceMotion ? 1 : 0.9,
					}}
					exit={{
						opacity: 0,
						scale: reduceMotion ? 1 : 0.5,
					}}
					title={
						isExpanded
							? translations('view_less')
							: translations('view_all')
					}
				>
					<Checkbox
						value="more"
						isSelected={isExpanded}
						aria-label={
							isExpanded
								? translations('view_less')
								: translations('view_all')
						}
						onChange={() => setIsExpanded((prev) => !prev)}
						className={twMerge(
							'relative block px-4 py-1',
							'rounded-full border-2 border-black/10 dark:border-white/10 text-lg',
							'cursor-pointer hover:shadow-lg',
							'transition-all',
							'bg-cornflower-200/60 text-cornflower-700 dark:bg-cornflower-200/90 dark:text-cornflower-700',
						)}
					>
						<Icon
							path={
								isExpanded ? mdiChevronLeft : mdiDotsHorizontal
							}
							size={1.15}
						/>
					</Checkbox>
				</motion.div>
			</CheckboxGroup>
		</>
	);
};

export const PortfolioTagLinks: FC<{ tags: string[] }> = ({ tags }) => {
	const translations = useTranslations('Portfolio.Tags');

	return (
		<ul
			aria-label="Tags"
			className="flex flex-wrap items-center justify-center gap-2 py-4"
		>
			{tags.map((tag) => (
				<li key={tag}>
					<Link
						lang="en"
						href={`/?portfolioTags=${tag}`}
						className={twMerge(
							'relative block px-4 py-1',
							'rounded-full border-2 border-black/10 dark:border-white/10 bg-white/80 text-lg dark:bg-slate-900/60',
							'cursor-pointer hover:shadow-lg',
							'transition-all',
						)}
						title={translations('view_items_with_tag', { tag })}
					>
						#{tag.toLocaleLowerCase()}
					</Link>
				</li>
			))}
		</ul>
	);
};

export default PortfolioTags;
