import { twMerge } from 'tailwind-merge';
import { motion, useReducedMotion } from 'motion/react';
import Icon from '@mdi/react';

const IconButton = ({
	icon,
	label,
	className,
	iconClassName,
	onClick,
}: {
	icon: string;
	label: string;
	className?: string;
	iconClassName?: string;
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => {
	const reduceMotion = useReducedMotion();

	return (
		<motion.button
			className={twMerge(
				'relative block rounded-full w-12 md:w-16 h-12 md:h-16 p-2 text-slate-800 bg-white border-2 border-black/10 dark:border-white/10 shadow-lg cursor-pointer',
				className,
			)}
			initial={{
				scale: reduceMotion ? 1 : 0.5,
				opacity: 0,
			}}
			animate={{
				scale: 1,
				opacity: 1,
				transition: {
					delay: 0.2,
				},
			}}
			whileHover={{
				scale: 1.05,
			}}
			whileTap={{
				scale: 0.9,
			}}
			title={label}
			aria-label={label}
			onClick={onClick}
		>
			<Icon path={icon} className={iconClassName} />
		</motion.button>
	);
};

export default IconButton;
