/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @next/next/no-img-element */

import { useId, useState } from 'react';
import { motion, useReducedMotion } from 'motion/react';
import { mdiOpenInNew } from '@mdi/js';
import EmojiText from '@/components/EmojiText/EmojiText';
import Card from '@/components/Card/Card';
import TextButton from '@/components/TextButton/TextButton';
import getTalks, {
	Talk,
	getFeaturedTalks,
} from '@/resources/messages/talks/talks';
import { mdiCalendar, mdiChevronDown, mdiMapMarker } from '@mdi/js';
import Icon from '@mdi/react';
import { useFormatter, useLocale, useTranslations } from 'next-intl';
import pSBC from 'shade-blend-color';
import BasicDialog from '@/components/BasicDialog/BasicDialog';
import { Locale } from '@/i18n/routing';

const Talks = () => {
	const locale = useLocale() as Locale;
	const translations = useTranslations('Talks');

	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<Card
			ariaLabel={translations('recent_talks_title')}
			title={{
				element: 'h3',
				text: translations('recent_talks_title'),
			}}
			className="flex flex-col"
		>
			<ol
				aria-label={translations('recent_talks_title')}
				className="-m-4 grid grow gap-2 p-4"
			>
				{getFeaturedTalks(locale).map((talk) => (
					<li
						key={`${talk.date}-${talk.title}`}
						className="max-sm:last-of-type:hidden"
					>
						<SingleTalkSummary
							talk={talk}
							onClick={() => setIsExpanded(true)}
						/>
					</li>
				))}
			</ol>
			<div className="mt-4 flex flex-wrap justify-center gap-4">
				<AllTalks
					isExpanded={isExpanded}
					setIsExpanded={setIsExpanded}
				/>
				<BookMe />
			</div>
		</Card>
	);
};

const SingleTalkSummary = ({
	talk,
	onClick,
}: {
	talk: Talk;
	onClick: () => void;
}) => {
	return (
		<div className="flex cursor-pointer flex-row gap-1" onClick={onClick}>
			<div className="flex shrink-0 flex-col items-center">
				<img
					src={talk.organization.logo.url}
					alt={talk.organization.logo.alt}
					className="h-10 w-10 shrink-0 rounded-full border-2 border-black/10 dark:border-white/10 object-cover lg:h-14 lg:w-14"
				/>
				<div className="mt-2 w-1 grow rounded-full bg-black/10 dark:bg-white" />
			</div>
			<div className="px-3">
				<h4 className="mb-1 text-xl font-bold md:text-2xl">
					<span
						className="dark:hidden"
						style={{
							color: `${pSBC(-0.3, talk.organization.color)}`,
						}}
					>
						{talk.title}
						<SingleTalkSummaryMetaData talk={talk} />
					</span>
					<span
						className="hidden dark:inline-block"
						style={{
							color: `${pSBC(0.5, talk.organization.color)}`,
						}}
					>
						{talk.title}
						<SingleTalkSummaryMetaData talk={talk} />
					</span>
				</h4>
				<p className="line-clamp-2 text-sm font-semibold">
					{talk.description}
				</p>
			</div>
		</div>
	);
};

const SingleTalkSummaryMetaData = ({ talk }: { talk: Talk }) => {
	const format = useFormatter();

	return (
		<span className="mb-2 grid gap-2 text-sm opacity-90 lg:flex">
			<span className="flex items-center gap-1">
				<Icon path={mdiCalendar} size={0.7} className="shrink-0" />
				{format.dateTime(new Date(talk.date), {
					day: 'numeric',
					month: 'short',
					year: 'numeric',
				})}
			</span>
			<span className="flex items-center gap-1">
				<Icon path={mdiMapMarker} size={0.7} className="shrink-0" />
				{talk.location}
			</span>
		</span>
	);
};

const AllTalks = ({
	isExpanded,
	setIsExpanded,
}: {
	isExpanded: boolean;
	setIsExpanded: (isExpanded: boolean) => void;
}) => {
	const locale = useLocale() as Locale;
	const translations = useTranslations('Talks');

	return (
		<>
			<TextButton
				isFlat
				label={translations('more_talks_button')}
				title={translations('more_talks_button_description')}
				icon={mdiChevronDown}
				onClick={() => setIsExpanded(true)}
			/>
			<BasicDialog
				isOpened={isExpanded}
				setIsOpened={setIsExpanded}
				title={translations('more_talks_button_details_title')}
			>
				{getTalks(locale).map((talk, index) => (
					<SingleTalk
						key={`${talk.date}-${talk.title}`}
						talk={talk}
						talkIndex={index}
					/>
				))}

				<motion.div
					initial={{
						opacity: 0,
					}}
					animate={{
						opacity: 1,
						transition: {
							delay: 0.2 + getTalks(locale).length * 0.05,
						},
					}}
				>
					<BookMe />
				</motion.div>
			</BasicDialog>
		</>
	);
};

const SingleTalk = ({ talk, talkIndex }: { talk: Talk; talkIndex: number }) => {
	const reduceMotion = useReducedMotion();
	const format = useFormatter();

	return (
		<Card
			variant="small"
			className={`flex flex-col items-stretch overflow-hidden p-0 shadow-none md:flex-row`}
			motionInitial={{
				opacity: 0,
				y: reduceMotion ? 0 : '10%',
			}}
			motionAnimate={{
				opacity: 1,
				y: 0,
				transition: {
					duration: 0.25,
					delay: 0.2 + 0.05 * talkIndex,
					ease: 'easeOut',
				},
			}}
		>
			<div
				className="w-22 flex shrink-0 items-center justify-between gap-4 p-4 ps-5 text-white md:flex-col md:items-start"
				style={{
					backgroundColor: talk.organization.color,
				}}
			>
				<div
					aria-label={format.dateTime(new Date(talk.date), {
						day: 'numeric',
						month: 'long',
						year: 'numeric',
					})}
				>
					<div className="text-4xl">
						{/* Get only day, padded with 0 */}
						{format.dateTime(new Date(talk.date), {
							day: '2-digit',
						})}
					</div>
					<div>
						{/* Get the month as 3 characters and the year */}
						{format.dateTime(new Date(talk.date), {
							month: 'short',
							year: 'numeric',
						})}
					</div>
				</div>
				<img
					src={talk.organization.logo.url}
					alt={talk.organization.logo.alt}
					className="h-14 w-14 shrink-0 rounded-full object-cover"
				/>
			</div>
			<div className="relative grow pt-3">
				<p className="mb-1 px-3 text-2xl font-bold">
					<span
						className="dark:hidden"
						style={{
							color: `${pSBC(-0.3, talk.organization.color)}`,
						}}
					>
						{talk.title}
					</span>
					<span
						className="hidden dark:inline-block"
						style={{
							color: `${pSBC(0.5, talk.organization.color)}`,
						}}
					>
						{talk.title}
					</span>
				</p>
				<div className="grid gap-2 px-3 text-base">
					{talk.description.split('\n').map((paragraph, index) => (
						<p key={index}>{paragraph}</p>
					))}
				</div>
				<div className="flex items-center gap-2 p-3">
					<Icon path={mdiMapMarker} size={0.8} />
					<p>{talk.location}</p>
				</div>
			</div>
		</Card>
	);
};

const BookMe = () => {
	const id = useId();
	const reduceMotion = useReducedMotion();
	const translations = useTranslations('Talks');

	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<>
			<TextButton
				layoutId={reduceMotion ? undefined : id}
				layoutDependency={isExpanded}
				isFlat
				label={translations('your_event_button')}
				title={translations('your_event_button_description')}
				onClick={(event) => {
					event.stopPropagation();
					setIsExpanded(true);
				}}
				className="w-fit"
			/>
			<BasicDialog
				isOpened={isExpanded}
				setIsOpened={setIsExpanded}
				title={translations('your_event_details_title')}
			>
				<EmojiText>
					{translations('your_event_details_description')}
				</EmojiText>
				<TextButton
					href="https://www.linkedin.com/in/tijmenennik"
					label="LinkedIn"
					title="LinkedIn"
					icon={mdiOpenInNew}
					className="mx-auto w-fit"
				/>
			</BasicDialog>
		</>
	);
};

export default Talks;
