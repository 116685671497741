'use client';

import { useEffect, useId, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { motion, useReducedMotion } from 'motion/react';
import { Dialog } from '@headlessui/react';
import { useTranslations } from 'next-intl';
import IconButton from '@/components/IconButton/IconButton';
import { mdiArrowRightCircle, mdiArrowUpCircle } from '@mdi/js';
import Icon from '@mdi/react';
import EmojiText from '../EmojiText/EmojiText';

const ProfessionButton = ({
	profession,
	description,
	rotation = 0,
	className,
	dialogClassName,
}: {
	profession: string;
	description: string;
	rotation?: number;
	className?: string;
	dialogClassName?: string;
}) => {
	const reduceMotion = useReducedMotion();
	const id = useId();
	const selfRef = useRef<HTMLButtonElement>(null);
	const translations = useTranslations('ProfessionButton');

	const [isExpanded, setIsExpanded] = useState(false);
	const [isClosing, setIsClosing] = useState(false);

	useEffect(() => {
		if (isClosing) {
			if (!reduceMotion) {
				setTimeout(() => {
					setIsClosing(false);
					setIsExpanded(false);
				}, 300);
			} else {
				setIsClosing(false);
				setIsExpanded(false);
			}
		}
	}, [isClosing, reduceMotion]);

	return (
		<>
			<motion.button
				ref={selfRef}
				layoutId={reduceMotion ? undefined : id}
				layoutDependency={isExpanded}
				className={twMerge(
					'group relative flex gap-2 max-w-xs ps-5 py-2 pe-2 items-center rounded-full border-2 border-black/10 dark:border-white/10 bg-white/80 text-start md:text-lg shadow-lg cursor-pointer',
					className,
				)}
				onClick={() => setIsExpanded(true)}
				whileHover={{
					scale: reduceMotion ? 1 : 1.05,
				}}
				whileTap={{
					scale: reduceMotion ? 1 : 0.9,
				}}
				initial={{
					rotate: rotation,
				}}
				title={translations('expand')}
				aria-expanded={isExpanded}
			>
				<EmojiText>{profession}</EmojiText>
				<Icon
					path={mdiArrowRightCircle}
					size={1}
					className="shrink-0 transition-all group-hover:rotate-45 group-focus:rotate-45"
				/>
			</motion.button>
			<Dialog
				open={isExpanded}
				onClose={() => setIsExpanded(false)}
				className="fixed inset-0 z-50 bg-black/10"
				onClick={() => {
					setIsExpanded(false);
				}}
			>
				<Dialog.Overlay className="fixed inset-0 z-10 backdrop-blur-md">
					<div
						className={twMerge(
							'h-full w-full bg-opacity-10',
							dialogClassName,
						)}
					/>
				</Dialog.Overlay>
				<Dialog.Panel
					className="fixed inset-0 z-50 h-screen overflow-y-auto p-4"
					onClick={() => setIsClosing(true)}
				>
					<div className="grid h-full max-h-screen w-full items-center justify-center">
						<motion.div
							layoutId={reduceMotion ? undefined : id}
							layoutDependency={isExpanded}
							className={twMerge(
								'relative grid max-w-xl border-2 border-black/10 dark:border-white/10 bg-white shadow-lg md:text-lg overflow-hidden',
								isClosing
									? 'rounded-full transition-all'
									: 'rounded-3xl',
								dialogClassName,
							)}
							onClick={(event) => event.stopPropagation()}
							tabIndex={-1}
						>
							<Dialog.Title
								className={twMerge(
									'flex gap-4 text-xl md:text-3xl ps-8 pe-2 py-2 items-center justify-between max-w-full',
									className,
								)}
							>
								<span className="py-2">
									<EmojiText>{profession}</EmojiText>
								</span>
								<IconButton
									icon={mdiArrowUpCircle}
									label={translations('collapse')}
									onClick={() => {
										setIsClosing(true);
									}}
									className="shrink-0"
									iconClassName={
										isClosing ? 'rotate-45' : '-rotate-45'
									}
								/>
							</Dialog.Title>
							<motion.div
								initial={{
									height: reduceMotion ? 'auto' : 0,
								}}
								animate={{
									height: isClosing ? 0 : 'auto',
									transition: {
										type: 'spring',
										duration: 0.25,
										delay: isClosing ? 0 : 0.25,
									},
								}}
								className="overflow-hidden"
							>
								<Dialog.Description className="grid gap-4 p-6">
									{description
										.split('\n')
										.map((paragraph, index) => (
											<span key={index}>
												<EmojiText>
													{paragraph}
												</EmojiText>
											</span>
										))}
								</Dialog.Description>
							</motion.div>
						</motion.div>
					</div>
				</Dialog.Panel>
			</Dialog>
		</>
	);
};

export default ProfessionButton;
