import { twMerge } from 'tailwind-merge';
import { AnimationProps, motion } from 'motion/react';

const Card = ({
	children,
	title,
	variant = 'base',
	className,
	style,
	onClick,
	onFocus,
	onBlur,
	tabIndex,
	ariaLabel,
	layoutId,
	layoutDependency,
	motionInitial,
	motionAnimate,
}: {
	children?: React.ReactNode;
	title?: {
		element?: string;
		text: string;
		className?: string;
	};
	variant?: 'small' | 'base';
	className?: string;
	style?: React.CSSProperties;
	onClick?: () => void;
	onFocus?: () => void;
	onBlur?: () => void;
	tabIndex?: number;
	ariaLabel?: string;
	layoutId?: string;
	layoutDependency?: unknown;
	motionInitial?: AnimationProps['initial'];
	motionAnimate?: AnimationProps['animate'];
}) => {
	const baseClasses = twMerge(
		'border-2 border-black/10 dark:border-white/10 p-4',
		variant === 'base' &&
			'rounded-3xl shadow-lg bg-white/80 dark:bg-slate-800/70',
		variant === 'small' &&
			'rounded-xl shadow-md bg-white dark:bg-slate-900',
		className,
	);

	const TitleElement = (title?.element ||
		'h3') as keyof JSX.IntrinsicElements;
	const TitleComponent = (
		<TitleElement
			className={twMerge('mb-2 text-center text-xl', title?.className)}
		>
			{title?.text}
		</TitleElement>
	);

	if (onClick || onFocus || onBlur)
		return (
			<motion.button
				type="button"
				layoutId={layoutId}
				layoutDependency={layoutDependency}
				onClick={onClick}
				whileHover={{ scale: 1.05 }}
				whileTap={{ scale: 0.95 }}
				className={twMerge('text-left', baseClasses)}
				style={style}
				onFocus={onFocus}
				onBlur={onBlur}
				tabIndex={tabIndex}
				aria-label={ariaLabel}
				initial={motionInitial}
				animate={motionAnimate}
			>
				{!!title && TitleComponent}
				{children}
			</motion.button>
		);

	return (
		<motion.section
			layoutId={layoutId}
			layoutDependency={layoutDependency}
			className={baseClasses}
			style={style}
			aria-label={ariaLabel}
			initial={motionInitial}
			animate={motionAnimate}
		>
			{!!title && TitleComponent}
			{children}
		</motion.section>
	);
};

export default Card;
